@import 'reset';
@import 'fonts';
@import "image-viewer";
@import 'editor';

@import '../assets/fonts/frutiger-lt-cufonfonts-webfont/style';

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=RobotoMono:wght@100;300;400;700&display=swap');


*,
html {
  font-family: Frutiger LT, 'sans-serif';
  box-sizing: border-box;
}

body {
  background-color: #f8f8f8;
  min-width: 1000px;
  touch-action: none;
}

html, body, #root{
  height: 100%;
}

// Fixes possibility to pan map from outside of the container div
.MapViewer-workaround {
  overflow: initial !important;
}

.login-input {
  border: 1px solid #ccc;
  border-radius: 2px;
  font-size: 14px;
  padding: 7px;
  margin: 5px 0;
  margin-bottom: 20px;
}

// disable the pannellum tooltip
.pnlm-about-msg {
  visibility: hidden;
  a {
    visibility: hidden;
  }
}

// custom hotspot icon spec
.pnlm-hotspot {
  background-color: #073c7a;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  border-radius: 100%!important;
  border: 1px solid #fff;
  background-image: unset !important;
  width: 30px !important;
  height: 30px !important;
}

.pnlm-CHAT{
  background-image: url("../assets/images/icons/icon_chat.svg")!important;
  background-size: 50%;
  background-position: center;
  background-repeat: no-repeat;
}

.pnlm-ANNOTATION{
  background-image: url("../assets/images/icons/icon_file.svg")!important;
  background-size: 50%;
  background-position: center;
  background-repeat: no-repeat;
}

.pnlm-TOUR{
  background-color: unset;
  box-shadow: unset;
  -webkit-box-shadow: unset;
  -moz-box-shadow: unset;
  border-radius: unset;
  border: none;
  width: 70px !important;
  height: 70px !important;
  background-image: url("../assets/images/icons/icon_tour.svg")!important;
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.35;
  -webkit-backface-visibility: hidden;
  outline: 1px solid transparent;
}

.down-arrow {
  display: inline-block;
  position: relative;
  background: #fff;
  text-decoration: none;
}
.down-arrow:after {
  content: '';
  display: block;
  position: absolute;
  left: 15px;
  top: 99.5%;
  width: 0;
  height: 0;
  border-top: 10px solid #ffffff;
  border-right: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid transparent;
}

.up-arrow {
  display: inline-block;
  position: relative;
  background: #f9f9f9;
  text-decoration: none;
}
.up-arrow:after {
  content: '';
  display: block;
  position: absolute;
  right: 12px;
  bottom: 99.2%;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #f9f9f9;
  border-left: 10px solid transparent;
}

.chat-message {
  text-align: start;
  background-color: #e8e8e8;
  color: #000000;
  margin: 5px;
  padding: 7px 10px;
  border-radius: 5px 5px 5px 0px;
  clear: both;
  float: left;
  font-size: 14px;
  line-height: 1.2em;
}

.chat-message.message-scroll-to{
  display: none;
}

.user-message {
  text-align: end;
  background-color: #073c7a;
  color: #ffffff;
  float: right;
  border-radius: 5px 5px 0 5px;
}

.chat-message-email {
  font-size: 0.75em;
  margin-left: 5px;
  text-align: start;
  clear: both;
  float: left;
  opacity: 0.5;
}

.selected-chat-user {
  background: #e8e8e8 !important;
}
.rdrDayToday .rdrDayNumber span:after {
  background: #073c7a!important;
}

.viewer{
  border-bottom: 7px solid #fff;
}

.viewer-focused{
  border-bottom: 7px solid #345EEE;
}

#forgeViewer{
  width: 100%;
  height: 100%;
  position: relative;
  background: #ededed;
}

.canvas-wrap{
  position: absolute;
  top: 0;
  width: 100vw;
}

#tooltip-info{
  display: none;
}
