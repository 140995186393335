.minimap-floor-heading {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  color: #05051d;
}

.minimap-date {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  text-align: right;
  text-transform: capitalize;
  color: #686868;
}
