.editor {
  width: 100vw;
  height: 100%;
  min-height: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: hidden;

  > p, h1, h2, h3, h4, h5, h6, span, li, ul, div, button, select {
    font-family: "Roboto Mono";
  }

  select {
    width: 100%;
    padding: 5px;
  }
  ::-webkit-scrollbar {
    width: 1em;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px rgba(0, 0, 0, 0);
  }

  ::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 2px;
  }
  ::-webkit-scrollbar {
    width: 5px;
  }
}


/* CSS styles for the annotorious widget */
.a9s-annotationlayer{
  z-index: 100;
}

.colorselector-widget {
  padding:5px;
  border-bottom:1px solid #e5e5e5;
}

.colorselector-widget button {
  outline:none;
  border:none;
  display:inline-block;
  width:20px;
  height:20px;
  border-radius:50%;
  cursor:pointer;
  opacity:0.5;
  margin:4px;
}

.colorselector-widget button.selected,
.colorselector-widget button:hover {
  opacity:1;
}

svg.a9s-annotationlayer .a9s-annotation.RED .a9s-inner,
svg.a9s-annotationlayer .a9s-annotation.GREEN .a9s-inner,
svg.a9s-annotationlayer .a9s-annotation.BLUE .a9s-inner {
  fill:transparent;
  stroke:none;
}

.active-tool{
  background: #f00;
  color: #fff;
}

.r6o-btn {
  background-color: #222!important;
  border: 1px solid #222!important;;
  color: #ccc!important;;
}

.r6o-btn.outline{
  background-color: unset!important;
  border: 1px solid #222!important;;
  color: #222!important;;
}

.a9s-handle .a9s-handle-inner {
  fill: #222!important;
  stroke: #ccc!important;
}

.selected .a9s-outer{
  stroke: none!important;
  fill: none;
}

svg.a9s-annotationlayer .a9s-outer {
  stroke-width: 1!important;
}

.a9s-shape-label {
  font-size: 1rem;
  color: white;
}

.editor foreignObject {
  overflow: visible;
}

.observation-table tr, td{
  padding: 5px;
  margin: 10px 0;
}
